import React from "react";
import styled from "styled-components";
const StyledWrapper = styled.div`
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 50%;
  max-height: 50%;
  overflow-y: auto;
  background: #fff;
  padding: 1rem;
  transform: translate(-50%, -50%);
  transform: translateY(${(p) => (p.isOpen ? 0 : "100%")});
  visibility: ${(p) => (p.isOpen ? "visible" : "hidden")};

  transition: visibility transform 0.35s;
`;

export default function Dialog({ isOpen, children }) {
  return (
    <StyledWrapper className="w-3/4 sm:1/3" isOpen={isOpen}>
      {children}
    </StyledWrapper>
  );
}
