import React from "react";
import styled from "styled-components";

export const SkillPillWrapper = styled.div`
  display: inline-block;
  position: relative;
  padding: 0.5rem 1rem;
  background: #3b82f6;
  color: #fff;
  margin: 0.5rem;
  font-size: 14px;
`;

export default function SkillsSection() {
  const skills = [
    "ReactJS",
    "NodeJS",
    "React Native(EXPO)",
    "Angular 6+",
    "Firebase",
    "MongoDB",
    "MySQL",
    "AWS",
    "HTML & CSS",
    "JavaScript",
    "Bootstrap",
    "Tailwind-CSS",
    "C",
    "C++",
    "JAVA",
    "PHP",
    "Python",
  ];
  return (
    <>
      {skills.map((item) => {
        return <SkillPillWrapper className="rounded-full" key={item}>{item}</SkillPillWrapper>;
      })}
    </>
  );
}
