import React from "react";
import { ChevronUp, Facebook, Linkedin, Mail } from "react-feather";
import styled from "styled-components";
const FooterWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #fff;
`;

const links = [
  {
    icon: <Facebook className="fill-current" strokeWidth="1" />,
    link: "https://www.facebook.com/iamluckybharat/",
  },
  {
    icon: <Linkedin className="fill-current" strokeWidth="1" />,
    link: "https://www.linkedin.com/in/bharatsharmajpr/",
  },
  {
    icon: <Mail />,
    link: "mailto:bhartjpr007@gmail.com",
  },
];

export default function Footer() {
  return (
    <FooterWrapper className="bg-blue-700">
      <span>{`Copyright ${new Date().getFullYear()} © BHARAT SHARMA`}</span>
      <ChevronUp
        onClick={() => window.scroll({ top: 0, left: 0, behavior: "smooth" })}
      />
      <div className="flex items-center">
        {links.map((item, index) => {
          return (
            <span key={index} className="mx-2">
              <a href={item.link} target="blank">
                {item.icon}
              </a>
            </span>
          );
        })}
      </div>
    </FooterWrapper>
  );
}
