import React from "react";
import EducationSection from "./components/EducationSection";
import Footer from "./components/Footer";
import HeadingText from "./components/HeadingText";
import OtherSkills from "./components/OtherSkills";
import ProjectsSection from "./components/ProjectsSection";
import SkillsSection from "./components/SkillsSection";

import ProfilePic from "./assets/profile.jpg";

function App() {
  return (
    <div className="w-full h-screen relative">
      <div className="h-full bg-blue-700 flex items-center justify-center">
        <div
          className="flex items-center flex-col-reverse sm:flex-row justify-center text-white px-5"
          style={{ maxWidth: "992px" }}
        >
          <div className="text-center sm:text-left">
            <div className="text-4xl font-medium">Bharat Sharma</div>
            <div className="text-blue-400 tracking-widest">
              Full Stack Developer.
            </div>
            <div className="mt-2 self-center w-full sm:w-3/4 text-sm text-blue-200 tracking-wide">
              Through constant learning and ceration i create software with high
              standard. I love working with frameworks but technologies I love
              right now are React, Nodejs and Styled Components.
            </div>
            <div className="text-center mt-4 px-4 py-3 bg-white shodow-lg rounded-lg text-blue-700 w-full sm:w-56">
              <a
                href="https://drive.google.com/file/d/1euifvGjYBJFw6KJcs2AgGL5ZQIaZy4wA/view?usp=sharing"
                target="_blank"
              >
                Download Resume
              </a>
            </div>
          </div>
          <div className="text-left sm:text-center mb-6 sm:mb-0">
            <img
              src={ProfilePic}
              className="w-56 sm:w-96 rounded-full border-white border-4"
              alt="profile"
            />
          </div>
        </div>
      </div>
      <div className="h-screen flex items-center justify-center flex-col">
        <HeadingText>Education</HeadingText>

        <EducationSection />
      </div>
      <div className="mt-4 bg-gray-50">
        <HeadingText className="pt-10">Projects</HeadingText>
        <ProjectsSection />
      </div>

      <div className="mt-4 flex flex-col">
        <HeadingText>Technical Skills</HeadingText>
        <div className="flex flex-wrap mt-6 justify-center w-3/4 self-center">
          <SkillsSection />
        </div>
      </div>

      <div className="mt-4 flex flex-col py-10">
        <HeadingText>Other Skills</HeadingText>
        <div className="flex flex-wrap mt-6 justify-center w-3/4 self-center">
          <OtherSkills />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
